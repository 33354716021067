import React from 'react';

const TopBannerComponent: React.FC = () => {  return (
  <div className='TopBanner'>
    
    <div className="box">
      <h1>Holbæk Taekwondoklub</h1>
      <img src='collage.png' />
    </div>
    
  </div>
  );
};

export default TopBannerComponent;