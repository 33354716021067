import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

interface MarkdownViewerProps {
  markdownUrl: string;
}

const MarkdownViewer: React.FC<MarkdownViewerProps> = ({ markdownUrl }: MarkdownViewerProps) => {
  const [markdownContent, setMarkdownContent] = useState<string>('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(markdownUrl);
        const text = await response.text();
        setMarkdownContent(text);
      } catch (error) {
        console.error('Error fetching Markdown:', error);
      }
    };

    fetchMarkdown();
  }, [markdownUrl]);

  return (
    <div>
      <ReactMarkdown>{markdownContent}</ReactMarkdown>
    </div>
  );
};

export default MarkdownViewer;